html, body {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    background: $primary;
    background: $primary-white;
    background-repeat: no-repeat;
    font-family: $gilroy;
    font-weight: 400;
    position: relative;    
    text-align: center;    
    color: $white;
}

/*
* Fonts
*/

h1 {
    //font-family: $gilroyextrabold;
    //font-size: 2.7rem;
    color: $white;
    font-weight: 800;
}

h2 {
    font-weight: 800;
    margin: 2rem;
}

//h3 {
    //font-family: $gilroyextrabold;
    //font-size: 2rem;
//}

p {
    font-size: 1.25rem;
    color: $white;
}

a {
    text-decoration: none;
    transition: all .5s ease-in-out;
    color: $dark;

    &:hover {
        color: $primary;
    }
}

ul, ol {
    list-style: none;
    padding-left: 0;
}

hr {
    color: rgba($white, 0.5);
}

/*
* Buttons and Misc
*/

.breadcrumbs {
    ul {
        list-style: none;
        padding: 0;
        margin: 0 0 1rem 0;
        display: flex;
        gap: 10px;
        font-size: 14px;
        color: $white;
        justify-content: center;

        li {
            display: inline;

            a {
                text-decoration: none;
                color: $white;

                &:hover {
                    text-decoration: none;
                }
                
            }

            &.current {
                font-weight: bold;
                color: $white;
                text-transform: capitalize;
            }
        }        
    }
}

.btn {
    font-family: $gilroy;
    font-weight: 800;
    display: inline-block; // Allow the text and ::after to flow inline naturally
    position: relative;    // Required for fallback positioning, if needed
    //padding: 0.5rem 1.5rem;
    padding: 0.7rem 0.6rem 0.5rem 0.6rem;
    @include media-breakpoint-up(lg) {
        padding: 1rem 3rem;
    }
    border-radius: var(--bs-border-radius-2xl);
    text-transform: uppercase;
    box-shadow: $box-shadow;

    &::after {
        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZmZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSIgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIGQ9Ik0xNS40MjkgNy40MjUgMjAgMTJsLTQuNTcxIDQuNTcyIj48L3BhdGg+CiAgPHBhdGggZD0iTTUuMTQzIDEySDIwIj48L3BhdGg+Cjwvc3ZnPg==');
        display: inline-block;   // Ensures alignment with text
        white-space: nowrap;     // Prevents wrapping to a new line
        margin-left: 0.5rem;     // Spacing between text and icon
        margin-top: 0.2rem;
        vertical-align: middle;  // Aligns with the last line of text
        transition: all 0.5s ease-in-out;
    }

    &:hover {
        background-color: $primary;
        color: $white;

        &::after {
            transform: translateX(3px); // Smooth hover effect
        }
    }
}

.animated-small {
    max-width: 150px;
    @include media-breakpoint-up(md) {
        max-width: unset; 
    }
}

/*
* Navigation
*/

#wrapper-navbar {   

    .above-nav {
        a {
            color: rgba($white, 0.75);
            padding: .2rem .5rem;
            border: 1px solid rgba($white, 0.75);
            border-radius: 30px;

            svg {
                fill: rgba($white, 0.75);
                transition: all .5s ease-in-out;
            }

            &:hover {
                color: $white;
                border: 1px solid rgba($white, 1);

                svg {
                    fill: $white;
                }
            }
        }
    }

    #main-nav {
        &.navbar {
            --bs-navbar-nav-link-padding-y: 1rem;
            --bs-navbar-nav-link-padding-x: 1rem;
            @include media-breakpoint-only(lg) {
                --bs-navbar-nav-link-padding-y: 0.5rem;
                --bs-navbar-nav-link-padding-x: 0.5rem;
            }

            .custom-logo-link {
                img {
                    max-width: 200px;
                    @include media-breakpoint-up(lg) {
                        max-width: 300px;
                    }
                }
            }
        }
        
        .hamburger-menu {
            background-color: transparent;
            border: none;
            cursor: pointer;
            display: flex;
            padding: 0;
        
            svg {
        
                .line {
                    fill: none;
                    stroke: $white; 
                    stroke-width: 6;
                    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
                    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
                }
                
                .line1 {
                    stroke-dasharray: 60 207;
                    stroke-width: 6;
                }
                
                .line2 {
                    stroke-dasharray: 60 60;
                    stroke-width: 6;
                }
                
                .line3 {
                    stroke-dasharray: 60 207;
                    stroke-width: 6;
                }
            }
        
            &.opened {
                svg {
                    .line1 {
                        stroke-dasharray: 90 207;
                        stroke-dashoffset: -134;
                        stroke-width: 6;
                    }
                    
                    .line2 {
                        stroke-dasharray: 1 60;
                        stroke-dashoffset: -30;
                        stroke-width: 6;
                    }
                    
                    .line3 {
                        stroke-dasharray: 90 207;
                        stroke-dashoffset: -134;
                        stroke-width: 6;
                    }
                }
            }
        }

        #navbarNavDropdown {
            position: relative;
            ul#main-menu {
                li.menu-item {
                    &.current-menu-item {
                        a {
                            position: relative;

                            &:before {
                                content : "";
                                position: absolute;
                                bottom: 3px;
                                width: 10%;
                                @include media-breakpoint-up(lg) {
                                    width: 70%;
                                }
                                border-bottom: 2px solid $primary;
                                transition: all .5s;
            
                            }
                        }
    
                    }

                    a {
                        font-size: 1.125rem;
                        color: $black;
                        position: relative;

                        &:before {
                            content : "";
                            position: absolute;
                            bottom: 3px;
                            width: 0;
                            border-bottom: 2px solid $primary;
                            transition: all .5s;
        
                        }
                        
                        &.dropdown-toggle {
                            &::after {
                                content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9IiNmZjYzMWUiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KIDxwYXRoIGQ9Im0xMiAxMy4xNjkgNC45NS00Ljk1IDEuNDE0IDEuNDE0TDEyIDE1Ljk5NyA1LjYzNiA5LjYzMyA3LjA1IDguMjE5bDQuOTUgNC45NVoiPjwvcGF0aD4KPC9zdmc+');
                                border: none;
                                margin: 0;
                                vertical-align: 0;
                                position: relative;
                                top: 4px;
                            }
                        }

                        &:hover {
                            &:before {
                                width: 10%;
                                @include media-breakpoint-up(lg) {
                                    width: 70%;
                                }
                            }
        
                        }
                    }
                }

                .dropdown {
                    position: static;
                }

                .dropdown-menu {
                    @include media-breakpoint-up(lg) {
                        padding: 1rem 0 2rem 0;
                        right: 0;
                        left:0;
                        display: flex !important;
                        transform: translate(0, 20px);
                        opacity: 0;
                        transition: all 0.5s cubic-bezier(0.26, 0.02, 0, 0.99);
                        pointer-events: none;

                    }
            
                    &.show {
                        @include media-breakpoint-up(lg) {
                            transform: translate(0, 0);
                            opacity: 1;
                            pointer-events: auto;
                            z-index: 9999;
                        }
                    }

                    ul {
                        list-style: none;
                        padding-left: 0rem;
                        @include media-breakpoint-up(xl) {
                            padding-left: 2rem;
                        }

                        li {
                            span {
                                &.dropdown-header {
                                    color: $primary;
                                    font-family: $gilroy;
                                    font-weight: 800;
                                    font-size: 1.5rem;
                                    text-transform: uppercase;
                                    margin-top: 1rem;

                                    &::after {
                                        content: "";
                                        background: none repeat scroll 0 0 $lightgray;
                                        bottom: -5px;
                                        display: block;
                                        height: 1px;
                                        position: relative;
                                        width: 15%;
                                    }
                                }
                            }
                        }

                    }
                }
            }

            &.navbar-collapse {
                font-size: 1.5rem;
                position: absolute;
                top: 60px;
                background-color: #ff3f75;
                width: 100vw;
                z-index: 11;
                padding: 0;
                left: 0;            
                @include media-breakpoint-up(md) {
                    margin: unset;
                    font-size: unset;
                    position: unset;
                    height: unset;
                    width: unset;
                    padding: unset;
                    left: unset;
                    z-index: unset;
                    background-color: unset;
                }

                ul {
                    &.navbar-nav {
                        height: 84vh;
                        display: flex;                    
                        justify-content: center;
                        align-items: center;                
                        @include media-breakpoint-up(md) {
                            height: unset;                  
                            justify-content: unset;
                            align-items: unset;    
                        }
                    }
                }
            }
        }

        .social-nav {
            svg {
                height: 22px;
                width: 22px;
                fill: $white;
                margin: 0 .3rem;
                @include media-breakpoint-up(lg) {
                    margin: 0;
                }
                @include media-breakpoint-up(xl) {
                    margin: 0 .3rem;
                }
            }
        }
    }
}

/*
* wrappers
*/

// Some basic padding for all wrappers
.wrapper {
	padding: $grid-gutter-width 0;
}

/* Reset hero wrapper padding to 0 */
#wrapper-hero {
    padding: 0;
    height: 100%;
    min-height: 250px;
    @include media-breakpoint-up(lg) {
        min-height: 300px;
    }

    .animated-character {
        img {
            opacity: 0;
            transform: scale(0);
            transition: none;
            max-width: 180px;
            @include media-breakpoint-up(lg) {
                //max-width: 400px;
                max-width: 330px;
            }
        }

        .animated-character-text {
            color: $white;
            display: flex;
            flex-direction: column;
            gap: .5rem;

            .animated-eyebrow {
                font-size: 1rem;
                display: flex;
                flex-wrap: wrap;
                line-height: 1rem;
                gap: 5px;
                @include media-breakpoint-up(md) {
                    line-height: unset;
                }
        
                span {
                    opacity: 0;
                    animation: none;
                }
            }
        
            .animated-heading {
                @extend h1;
                display: block;
                font-family: $gilroy;
                font-weight: 700;
                font-size: 1.3rem;

                @include media-breakpoint-up(md) {
                    font-size: 2.7rem;
                }
                @include media-breakpoint-up(xl) {
                    font-size: 5rem;
                }
                margin: 0;

                span {
                    display: inline-block;
                    span {
                        display: inline-block;
                        opacity: 0; /* Start with the spans hidden */
                        transform: translateY(10px);
                    }
                }
            }

            .btn {
                opacity: 0;
            }
        }
    }  

    .swiper-button-prev, .swiper-button-next {
        color: $white;
    }

    .swiper-button-prev {
        left: 10px;
    }

    .swiper-button-next {
        right: 10px;
    }

    &.page {
        .animated-character-single {
            img {
                opacity: 0;
                transform: scale(0);
                transition: none;
                max-width: 180px;
                @include media-breakpoint-up(lg) {
                    //max-width: 400px;
                    max-width: 330px;
                }
            }

            .animated-character-text {
                color: $white;
                display: flex;
                flex-direction: column;
                gap: .5rem;

                .animated-eyebrow {
                    font-size: 1rem;
                    display: flex;
                    flex-wrap: wrap;
                    line-height: 1rem;
                    gap: 5px;
                    @include media-breakpoint-up(md) {
                        line-height: unset;
                    }
            
                    span {
                        opacity: 0;
                        animation: none;
                    }
                }
            
                .animated-heading {
                    @extend h1;
                    display: block;
                    font-family: $gilroy;
                    font-weight: 700;
                    font-size: 1.3rem;
            
                    @include media-breakpoint-up(md) {
                        font-size: 2.7rem;
                    }
            
                    @include media-breakpoint-up(xl) {
                        font-size: 5rem;
                    }
                    margin: 0;
            
                    /* This will ensure the visibility of the heading text */
                    opacity: 1; /* Set to 1 so the heading starts visible */
                    visibility: visible; /* Ensure visibility */
            
                    span {
                        display: inline-block;
                        opacity: 0; /* Initially hidden */
                        animation: none;
                        visibility: hidden; /* Ensure visibility is hidden initially */
                        transition: opacity 0.3s ease-in-out; /* Smooth transition for opacity */
                    }
            
                    span span {
                        opacity: 0; /* Make sure inner spans are also hidden initially */
                    }
                }
            }
        }  
    }
}

/*
* Main content panels
*/


#care-plans, #development-plans {

    .row {
        margin: 2rem 0;

        .plan-card-wrapper {
            margin: 1rem 0;

            .plan-card {
                position: relative;
                border: 2px solid $white; 
                border-radius: 15px;
                padding: 1rem;
                height: 100%;
                @extend .shadow;
                background-color: rgba($black, .2);
                
                span {               

                    &.description {
                        font-weight: $font-weight-bold;
                        font-size: 1.2rem;
                        display: block;
                        color: $secondary;
                        margin-bottom: 1rem;                        
                    }

                    &.price {
                        display: block;
                        color: $tetrachromacy;
                        font-weight: $font-weight-bold;
                        font-size: 1.3rem;
                        margin: .5rem 0;

                        small {
                            font-size: 1rem;
                        }
                    }
                }

                h3 {
                    font-family: $gilroy;
                    font-weight: 400;
                    color: $tertiary;
                    font-weight: $font-weight-bold;
                    margin: 0;
                }

                ul {

                    li {
                        margin: .5rem 0;
                        color: $white;                        

                        font-weight: $font-weight-normal;    

                        &:first-child {                            
                            color: $white;
                            font-weight: $font-weight-bold;
                        }

                        &:before {
                            content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIGZpbGw9IiNmZmZmMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMTIgMjJDNi40NzcgMjIgMiAxNy41MjMgMiAxMlM2LjQ3NyAyIDEyIDJzMTAgNC40NzcgMTAgMTAtNC40NzcgMTAtMTAgMTBabS0uOTk3LTYgNy4wNy03LjA3MS0xLjQxNC0xLjQxNC01LjY1NiA1LjY1Ny0yLjgyOS0yLjgyOS0xLjQxNCAxLjQxNEwxMS4wMDMgMTZaIj48L3BhdGg+Cjwvc3ZnPg==');
                            position: relative;
                            margin: 0 .2rem 0 0;
                            vertical-align: middle;

                        }

                        &.hosting-features {
                            &:hover {
                                cursor: pointer;
                            }
                        }

                    }

                }
                
                a.stripe-links {
                    &::after {
                        content: unset;
                    }
                }

            }
            
        }

    }

}

#what-we-offer, #why-partner-with-us, #dev-panels  {
    ol, ul {
        list-style: none; 
        display: flex; // Enable flexbox for the container
        flex-wrap: wrap; // Allow items to wrap onto the next row if needed
        gap: 1rem; // Space between items (optional, replaces margin)   
        @include media-breakpoint-up(lg) {
            flex-direction: row; // Ensure rows on larger screens
        }
        
        li {
            @include media-breakpoint-up(lg) {
            flex: 1 1 calc(33.33% - 1rem); // 50% width minus the gap
            }
            box-sizing: border-box; // Ensure padding/border are included in width
            display: flex; // Enable flexbox for the <li>
            flex-direction: column; // Align children in a column
            align-items: center; // Center children horizontally
            margin: 1.5rem 0; // Remove external margin to avoid conflicting with gap
            position: relative;
            background-color: $white;
            border-radius: 15px;
            padding: 1rem 1rem 0;
            transition: all .2s ease-in;
            border: 3px solid $white;
            width: 100%;
            @extend .shadow;
            background-image: url('/images/bg-patterns/food-pattern-small-bg.webp');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;

            a {
                display: flex;
                flex-direction: column;
                height: 100%;
                overflow: hidden;
            }

            h3 {
                color: $primary;
                font-weight: 800;
                margin: 2rem auto 1rem;
                @include media-breakpoint-up(xl) {
                    max-width: 75%;
                }
            }

            p {
                color: $dark;
                font-weight: $font-weight-bold;
                transition: all .5s ease-out;
                font-size: 1.1rem;
            }
    
            span {
                font-weight: 800;
                font-size: 3rem;
                //border: 2px solid $white;
                border-radius: 100%;
                height: 80px;
                width: 80px;
                display: inline-block;
                margin-bottom: 1rem;
                @extend .shadow;

                position: absolute;
                top: -40px;
                background-color: $white;
                padding: .2rem;
                color: $primary;

                left: 0;
                right: 0;
                margin: 0 auto;
            }

            img {
                height: 80px;
                width: 80px;
                display: inline-block;
                margin-bottom: 1rem;
                border-radius: 100%;
                @extend .shadow;
                position: absolute;
                top: -40px;
                background-color: $white;
                padding: .2rem;               
                
                left: 0;
                right: 0;
                margin: 0 auto;

            }

            p.cta {
                color: $dark;
                font-weight: 800;
                position: absolute;
                top: 50%;
                transform: translateY(-150px);
                opacity: 0;
                max-width: 75%;
                margin: 0 auto;
                left: 0;
                right: 0;
            }

            svg {
                height: 48px;
                width: 48px;
                fill: none;
                stroke: $primary;
                transform: rotate(-45deg);
                margin-top: auto;
                align-self: flex-end;
                transition: all .5s ease-out;
                margin-bottom: .5rem;
            }

            &:hover:not(.no-hover) {
                cursor: pointer;

                /*img, span {
                    position: absolute;
                    animation: roll 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);       
                }*/

                p:not(.cta ) {
                    transform: translateY(500px);
                    opacity: 0;
                }

                p.cta {
                    transform: translateY(0px);
                    opacity: 1;
                }

                svg {
                    transform: rotate(0deg);
                }
            }
            
        }
    }    
}

#why-partner-with-us {
    ul {
        li {
            span {
                background-color: $secondary;
                border: 3px solid $white;
                box-shadow: none !important;
            }

            /*&:hover {
                background-color: $white;
                cursor: unset;

                h3,p {
                    color: $primary;
                }
            }*/
        }
    }
}

#html-sitemap {
    .sitemap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;

        background-color: $white;
        border-radius: 15px;
        padding: 2rem;
        @extend .shadow;

        ul {
            h3 {
                color: $primary;
                font-weight: 800;
            }

            li {
                a {
                    color: $dark;
                    @extend p;
                    text-transform: capitalize;
                }
            }
        }
    }
}

#logo-slider {
    .logo_slider {
        .swiper-wrapper {
            -webkit-transition-timing-function: linear;
            -o-transition-timing-function: linear;
            transition-timing-function: linear;
            margin: 0 auto;

            .logo_slider_slides {
                background-color: $white;
                display: flex;
                justify-content: center;
                padding: 1rem;
                transition: all .5s ease-in;
                @extend .shadow;
                border-radius: 15px;

                img {
                    max-width: 128px;
                    max-height: 128px;   
                    object-fit: contain;
                    transition: all .5s ease-in;
                    
                }
            }
        }
    }
}

#why-wordpress {
    position: relative;
    background-color: $white;
    color: $primary;
    border-radius: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 90px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    @extend .shadow;
    padding: 1rem;
    @include media-breakpoint-up(md) {
        border-top-right-radius: 180px;
    }

    p {
        color: $primary;
        font-weight: 800;
    }

    .brand-image {
        position: absolute;
        top: 0px;
        right: 0px;
        padding: .5rem;
        height: 60px;
        width: 60px;
        border-top-right-radius: 15px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 51px;
        border-bottom-right-radius: 0;
        @include media-breakpoint-up(md) {
            height: 120px;
            width: 120px;
        }


        img {
            border-radius: 100%;
            filter: invert(1);
            @extend .shadow;
        }
    }

    ul {
        @include media-breakpoint-up(md) {
            display:grid;
            grid-template-columns: 50% 50%;
            grid-column-gap:10px;            
            margin: 1rem 3rem;
        }

        li {
            margin: 2rem 0; 

            p {
                @include media-breakpoint-up(md) {
                    margin: 0 5rem;
                }
            }
        }

    }

    &.home-security-audit {
        background-image: url('/images/animations/chef-coding.webp');
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, .9) !important;
        background-position: right;
    }
}

#dont-stop-there {
    ul {
        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-columns: 50% 50%;
            grid-column-gap: 10px;
            margin: 3rem;
        }

        li {

            p {
                margin-bottom: 0;
            }

            a {
                font-weight: 600;
                color: $white;
                font-size: 1rem;
                position: relative;
              
                &:before {
                  content: '';
                  display: inline-block;
                  width: 18px; /* Set your SVG's width */
                  height: 18px; /* Set your SVG's height */
                  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZmZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSIgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIGQ9Ik0xNS40MjkgNy40MjUgMjAgMTJsLTQuNTcxIDQuNTcyIj48L3BhdGg+CiAgPHBhdGggZD0iTTUuMTQzIDEySDIwIj48L3BhdGg+Cjwvc3ZnPg==');
                  background-size: contain;
                  background-repeat: no-repeat;
                  position: relative;
                  top: 4px;
                  left: -3px;
                  transition: all 0.3s ease-in;
                  opacity: 1;
                }
              
                &:hover {
                  color: $primary;
              
                  &:before {
                    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmMzY3MSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSIgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIGQ9Ik0xNS40MjkgNy40MjUgMjAgMTJsLTQuNTcxIDQuNTcyIj48L3BhdGg+CiAgPHBhdGggZD0iTTUuMTQzIDEySDIwIj48L3BhdGg+Cjwvc3ZnPg==');
                    opacity: 1;
                  }
                }
              }
              
        }
    }
}

#why-hosting {
    ul {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;

        li {
            position: relative;
            flex: 1;
            background-color: $white;
            color: $white;
            padding: 1rem;
            border-radius: 15px;
            margin-top: 4rem;
            @extend .shadow;

            h3 {
                font-weight: 800;
                margin-top: 2rem;
                color: $primary
            }

            p {
                color: $primary;
            }

            .icon-wrapper {
                position: absolute;
                background-color: $white;
                border-radius: 100%;
                margin: 0 auto;
                width: 100px;
                left: 0;
                right: 0;

                top: -40px;
                padding: .2rem;
                width: 80px;
                height: 80px;

                @extend .shadow;

                svg {
                    width: 100%;
                    height: 100%;
                    fill: $primary;
                }

            }
        }
    }
}

#why-webolicious {
    ul {
        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-columns: 50% 50%;
            grid-column-gap: 10px;
        }
        margin: 1rem 3rem;

        li {
            margin: 2rem 0;
        }
    }
}

#legal {
    background-color: $white;
    color: $dark;
    padding: 1rem 2rem;
    border-radius: 15px;
    @extend .shadow;

    h2 {
        margin: 1rem 0;
    }

    h2,h3,ul,p {
        color: $dark;
    }

    hr {
        color: $primary;
        margin: 1rem 0;
    }
}

/*
* FAQ Accordions
*/ 
#faq-section {
    background-color: $white;
    padding: 1rem;
    border-radius: 15px;
    @extend .shadow;

    #accordionFAQs {
        .accordion-item {
            background: none;

            .accordion-header {
                margin: 0;

                .accordion-button {
                    background: none;
                    font-weight: 800;
                    padding: var(--bs-accordion-btn-padding-y) 0;
                    @include media-breakpoint-up(md) {
                        padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
                    }

                    &:focus {
                        box-shadow: none; /* Removes the blue focus outline */
                        outline: none;    /* Ensures no outline is shown */
                    }
                }  
            } 

            .accordion-body {
                font-size: 1.1rem;
                padding: .5rem 0;
                @include media-breakpoint-up(md) {
                    padding: .5rem var(--bs-accordion-body-padding-x);
                }

            }
        } 
    }
}

/*
* Forms
*/

form {    

    &:not(#newsletter) {
        text-align: left;
        border: 1px solid white;
        padding: 2rem;
        border-radius: 15px;
        @extend .shadow;
        background-color: $white;
        color: $primary;
    }

    label {
        font-weight: $font-weight-bold;
        font-size: 1.125rem;
        color: $primary;
    }

    input.form-control, textarea.form-control, select.form-select {
        border-radius: 15px;
    }
}

.login-form {
    background: rgba($dark, 0.1);
    backdrop-filter: blur(5px);
    padding: 2rem;
    border-radius: 15px;
    @extend .shadow;
}

.contact-panel {
    background-color: $white;
    color: $primary;
    border-radius: 15px;
    padding: 2rem;

    a {
        color: $primary;
        font-weight: 800;
    }
}

/*
* Blogs
*/

/*.blog-archive {
    ul.post-loop {
        list-style: none; 
        display: flex; // Enable flexbox for the container
        flex-wrap: wrap; // Allow items to wrap onto the next row if needed
        gap: 1rem; // Space between items (optional, replaces margin)   
        @include media-breakpoint-up(md) {
            flex-direction: row; // Ensure rows on larger screens
        }  
        
        li {
            @include media-breakpoint-up(md) {
                flex: 1 1 calc(33.33% - 1rem); // 50% width minus the gap
            }
            box-sizing: border-box; // Ensure padding/border are included in width
            display: flex; // Enable flexbox for the <li>
            flex-direction: column; // Align children in a column
            align-items: center; // Center children horizontally
            margin: 1.5rem 0; // Remove external margin to avoid conflicting with gap
            width: 100%;

            img {
                height: 400px;
                width: 400px;
                object-fit: cover;
                border-radius: 15px;
                @extend .shadow;
            }

            h2 {
                max-width: 400px;
            }
        }
        
    }
}*/

.blog-template {

    .animated-image {
        border-radius: 15px;
    }

    .post-loop {

        article {            
            min-height: 400px;
            
            .article-wrapper {
                @extend .shadow;
                border-radius: 15px;
                overflow: hidden;
                height: 100%;
                width: 100%;

                &:hover {
                    a {
                        transform: scale(1.2);
                        box-shadow: none;

                        .blog-text-wrapper {
                            transform: translateY(200px) scale(0.8);
                        }

                        .blog-text-cta {
                            opacity: 1;
                        }
                    }
                }

                a {
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center center;
                    text-align: start;
                    padding: 1rem;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    box-shadow: inset 0 0 0 2000px rgba(0,0,0,.4);                    

                    .blog-text-wrapper {
                        margin: auto 0 0 0;
                        transition: all .5s ease-out;

                        span {
                            color: $white;
                        }

                        h2 {
                            color: $white;
                            width: 75%;
                            font-size: 1.5rem;
                            margin: 0;
                        }

                    }

                    .blog-text-cta {
                        position: absolute;
                        bottom: 15px;
                        right: 15px;
                        transform: rotate(-45deg);
                        opacity: 0;
                        margin: 1.5rem;
                        transition: opacity .1s ease-in;
                        transition-delay: .4s;
                    }
                }
            }
        }
    }
}

article {
    &.single {
        text-align: start;
        background-color: $white;
        color: $dark;
        border-radius: 15px;
        padding: 2rem;
        @extend .shadow;

        h2 {
            color: $primary;
            @extend .mb-2;
            @extend .mx-0;

            &:not(:first-child) {
                @extend .mt-5;
            }
        }

        h3 {
            color: $dark;
        }

        p {
            color: $dark;
        }
    }
}

/*
* Toggles
*/
.bill-type {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.125rem;
    gap: 10px;

    .bill-toggle {
        #bill-toggle {
            display: none;
            & + label {
                @extend .shadow;
                display: block;
                width: 60px;
                height: 32px;
                border-radius: 20px;
                background-color: $white;
                position: relative;
                cursor: pointer;
                &::after {
                content: '';
                display: block;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                background-color: $primary;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-100%, -50%);
                transition: transform 0.2s;
                }
            }
            &:checked + label::after {
                transform: translate(0, -50%);
            }
        }
    }
}

.page-terms-conditions, .page-privacy-cookie-policy {
    #legal {
        ul {
            list-style: unset;
            padding-left: 2rem;
        }
    }
}

/*
* Footer
*/

#wrapper-footer {

    h3 {
        color: $primary;
    }

    h5 {
        font-weight: $font-weight-bold;
    }

    ul {
        list-style: none;
        padding-left: 0;

        li {
            transition: all .5s ease-in; 
            
            a {
                color: $black;        
                font-size: 1.25rem;
            }

            &:hover { 
                a {
                    color: $primary; 
                }
            }
        }
    }

    .social_icons {
        svg {
            height: 22px;
            width: 22px;
            fill: $primary;
            margin: 0 .3rem;
        }
    }
}

/*
* Cookie bar
*/

#pjAcceptCookieBar {
    margin: 1rem;
    position: relative;
    z-index: 10;

    .animated-cookie {
        position: absolute;
        right: 0px;
        top: -50px;
        height: 115px;
        width: 115px;
        border-radius: 100%;
        padding: .5rem;
        background-color: #fff;
        @include media-breakpoint-up(md) {
            top: -58px;
            height: 150px;
            width: 150px;
        }

        img {
            height: 100%;
            width: 100%;            
        }
    }

    form {
            padding: 1rem;

        h2 {
            margin: 1rem 0;
        }

        span {
            display: block;
            color: $black;
            font-weight: 800;
            margin-bottom: .5rem;
        }

        button {
            @extend .btn;
            @extend .btn-primary;
            color: $white;
            margin: 1rem 0;
            display: block;
        }

        small {
            color: $black;
        }

    }

}

/*
* Animation keyframes
*/

@keyframes bounce-in {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }
    60% {
        transform: translateY(-20px);
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 1; // Ensure characters remain visible
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes image-scale-in {
    0% {
        transform: scale(0);
        opacity: 0; /* Hide image initially */
    }
    100% {
        transform: scale(1);
        opacity: 1; /* Show image when animation completes */
    }
}

/* Add the animate-img class to trigger the image scale animation */
img.animate-img {
    animation: image-scale-in 0.6s forwards ease-in-out;
}

@keyframes spin-and-move {
    0% {
        transform: rotate(0deg) translateX(0);
    }
    100% {
        transform: rotate(-720deg) translateX(-350%); /* Rotate and move left */
    }
}

@keyframes roll {
    0% {
        transform: translateX(0) rotate(0deg); /* Start at the original position */
    }
    50% {
        transform: translateX(-300px) rotate(-1080deg); /* Move and roll */
    }
    100% {
        transform: translateX(0px) rotate(0deg); /* Move and roll */
    }
}