/* Font: Gilroy */
@font-face {
    font-family: "Gilroy";
    src: url("/fonts/Gilroy-Light.woff2") format("woff2");
    font-weight: 300; /* Light */
    font-display: swap;
}

@font-face {
    font-family: "Gilroy";
    src: url("/fonts/Gilroy-Regular.woff2") format("woff2");
    font-weight: 400; /* Regular */
    font-display: swap;
}

@font-face {
    font-family: "Gilroy";
    src: url("/fonts/Gilroy-Bold.woff2") format("woff2");
    font-weight: 700; /* Bold */
    font-display: swap;
}

@font-face {
    font-family: "Gilroy";
    src: url("/fonts/Gilroy-ExtraBold.woff2") format("woff2");
    font-weight: 800; /* ExtraBold */
    font-display: swap;
}

@import "theme/theme_variables";  // <-------- Add your variables into this file. Also add variables to overwrite Bootstrap or webolicious_developments variables here
@import "assets/bootstrap";      // <-------- Loads Bootstrap5
@import "webolicious_developments/webolicious_developments";  // <-------- Loads the webolicious_developments defaults. Just a few classes to incorporate BS in WP

// Optional files - If you dont use the corresponding scripts/fonts comment em out
//@import "assets/font-awesome";    // <-------- Font Awesome Icon font

// Any additional imported files //
@import "theme/theme";            // <-------- That's where you can add your own design. Thats your part!
